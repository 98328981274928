import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";

import {
  AdditionalInfoTab,
  FacilityTab,
  FuelProductsTab,
  LegacyIdsTab,
  NetworkTab,
  OpeningHoursTab,
  RetailerTab,
  ExternalIdsTab,
  RetailerTabGSAP,
  SpaceUtilisationTab,
  
  } from "./components";
import { CustomerMissionTab } from "../ev-site-details/components";

import { useFacilityTypeCodesData } from "../../../../../../hooks";
import { Locale } from "../../../../../../utils/localization";
import { useQuery } from "react-query";
import { SitesService } from "../../../../../../services/sites.service";
import { AppConst, SiteStatusConst } from "../../../../../../constants";
import { EVSiteDetails } from "../ev-site-details/EVSiteDetails";

import "./RetailAdminSiteDetails.scss";

const fetchSiteFacilityData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteFacilitySubtypeResult",
  });
};

const fetchSiteFuelProductsData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteProductClassResult",
  });
};

const fetchSiteFacilityOpeningHourdData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteOpeningHoursResults",
  });
};

const fetchExternalIdData = ({ queryKey }: any) => {
  const siteId = queryKey[1];
  return SitesService.getSiteDetails({
    globalSiteId: siteId || "",
    viewName: "SiteExternalIdResult",
  });
};

interface IProps {
  ref?: any;
  siteId: string;
  basicSiteDetails: any;
  isBasicSiteDetailsLoading: boolean;
  isEditing: boolean;
  isTerritoryCodesLoading: boolean;
  isCountryCodesLoading: boolean;
  countryCodes: any;
  territoryCodes: any;
  refetchTerritoryCodes: any;
  onBasicDetailsPatchDataValueSet: any;
  onBasicDetailsEditedDataValueSet: any;
  sectionEditedData: any;
  selectedCategory: string;
  selectedStatus: { id: string; value: string };
  classifiedSiteType: string;
  operatingPlatform: { id: string; value: string } | null;
  refetchAllData?: boolean;
  isCreate?: boolean;
  onChangeCategory: (category: string) => void;
  siteTypeSelectedValue: { id: string; value: string };
  countrySelectedValue: { id: string; value: string };
}

export const RetailAdminSiteDetails = React.memo(
  forwardRef((props: IProps, ref) => {
    const {
      siteId,
      basicSiteDetails,
      isBasicSiteDetailsLoading,
      isEditing,
      isTerritoryCodesLoading,
      isCountryCodesLoading,
      countryCodes,
      territoryCodes,
      refetchTerritoryCodes,
      onBasicDetailsPatchDataValueSet,
      onBasicDetailsEditedDataValueSet,
      sectionEditedData,
      selectedCategory,
      selectedStatus,
      classifiedSiteType,
      refetchAllData,
      isCreate,
      onChangeCategory,
      siteTypeSelectedValue,
      countrySelectedValue,
      operatingPlatform,
    } = props;

    const [basicDetailsTabSelected, setBasicDetailsTabSelected] = useState<
      number | undefined
    >();
    const [isTabValueChanged, setIsTabValueChanged] = useState(false);

    const [isFacilitySectionValid, setIsFacilitySectionValid] = useState(0);
    const [facilitySectionInvalidText, setFacilitySectionInvalidText] =
      useState("");
    const [isFuelProductsSectionValid, setIsFuelProductsSectionValid] =
      useState(0);
    const [fuelProductsSectionInvalidText, setFuelProductsSectionInvalidText] =
      useState("");
    const [isOpeningHoursSectionValid, setIsOpeningHoursSectionValid] =
      useState(0);
    const [openingHoursSectionInvalidText, setOpeningHoursSectionInvalidText] =
      useState("");
    const [isRetailerSectionValid, setIsRetailerSectionValid] = useState(0);
    const [isNetworkSectionValid, setIsNetworkSectionValid] = useState(0);
    const [isAdditionalInfoSectionValid, setIsAdditionalInfoSectionValid] =
      useState(0);
    const [isLoyaltyIndicatorValid, setIsLoyaltyIndicatorValid] = useState(0);
    const [loyaltyIndicatorInvalidText, setloyaltyIndicatorInvalidText] =
      useState(Locale.common?.required_field);
    const [isFaxNoValid, setIsFaxNoValid] = useState(0);
    const [isUSACompanyValid, setIsUSACompanyValid] = useState(0);
    const [evDetailsTabSelected, setEVDetailsTabSelected] = useState<
      number | undefined
    >();
    

    const [isSpaceUtilizationSectionValid, setIsSpaceUtilizationSectionValid] =
      useState(0);
    const [isSiteSizeAreaValid, setIsSiteSizeAreaValid] = useState(0);
    const [isShopBuildingSizeValid, setIsShopBuildingSizeValid] = useState(0);

    const {
      isLoading: isFacilityTypeCodesLoading,
      data: facilityTypeCodes,
      refetch: refetchFacilityTypeCodes,
    } = useFacilityTypeCodesData();

    const {
      isLoading: isSiteFacilityDataLoading,
      data: fetchedSiteFacilityData,
      refetch: refetchFacilityData,
    } = useQuery(
      [`site-facility-data-${siteId}`, siteId],
      fetchSiteFacilityData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isFuelProductsDataLoading,
      data: fetchedFuelProductsData,
      refetch: refetchSiteFuelProductsData,
    } = useQuery(
      [`site-fuel-products-data-${siteId}`, siteId],
      fetchSiteFuelProductsData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isOpeningHoursDataLoading,
      data: fetchedOpeningHoursData,
      refetch: refetchSiteOpeningHoursData,
    } = useQuery(
      [`site-opening-hours-data-${siteId}`, siteId],
      fetchSiteFacilityOpeningHourdData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    const {
      isLoading: isExternalIdDataLoading,
      data: fetchedExternalIdData,
      refetch: refetchExternalIdData,
    } = useQuery(
      [`site-external-id-data-${siteId}`, siteId],
      fetchExternalIdData,
      {
        staleTime: AppConst.VIEW_SITE_DETAILS_STALE_TIME,
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    useEffect(() => {
      if (isEditing) {
        refetchFacilityTypeCodes();
        setIsFacilitySectionValid(0);
        setIsFuelProductsSectionValid(0);
        setIsOpeningHoursSectionValid(0);
        setIsRetailerSectionValid(0);
        setIsNetworkSectionValid(0);
        setIsAdditionalInfoSectionValid(0);
        setIsSiteSizeAreaValid(0);
        setIsShopBuildingSizeValid(0);
        setIsSpaceUtilizationSectionValid(0);
        setEVDetailsTabSelected(0);
      }
    }, [isEditing]);

    useEffect(() => {
      if (refetchAllData && siteId) {
        if (basicDetailsTabSelected === 0) {
          refetchFacilityData();
        } else if (basicDetailsTabSelected === 1) {
          refetchSiteFuelProductsData();
        } else if (basicDetailsTabSelected === 2) {
          refetchSiteOpeningHoursData();
        } else if (basicDetailsTabSelected === 5) {
          refetchExternalIdData();
        }
      }
    }, [refetchAllData, basicDetailsTabSelected]);

    useEffect(() => {
      if (siteId) {
        refetchFacilityData();
        refetchSiteFuelProductsData();
        refetchSiteOpeningHoursData();
        refetchExternalIdData();
      }
    }, [siteId]);

    useEffect(() => {
      if (classifiedSiteType) {
        if (
          classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
          classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
          classifiedSiteType === AppConst.PARTNER_SITE_TYPE ||
          classifiedSiteType === AppConst.RBLA_SITE_TYPE
        ) {
          setBasicDetailsTabSelected(0);
        } else if (classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE) {
          setBasicDetailsTabSelected(4);
        } else {
          setBasicDetailsTabSelected(undefined);
        }
      }
    }, [classifiedSiteType]);

    const handleBasicDetailsTabSelect = (
      event: TabStripSelectEventArguments
    ) => {
      setBasicDetailsTabSelected(event.selected);
    };

    const onTabValueChanged = (flag: boolean) => {
      setIsTabValueChanged(flag);
    };

    const onRetailersDataChange = (isValid: number) => {
      setIsRetailerSectionValid(isValid);
    };
    const [isEvOverviewValueChanged, setIsEvOverviewValueChanged] =
    useState(false);

    useImperativeHandle(ref, () => ({
      triggerValidations(): boolean {
        setIsAdditionalInfoSectionValid(0);
        if (
          classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE ||
          classifiedSiteType === AppConst.PARTNER_SITE_TYPE
        ) {
          return true;
        }
        let isValid = true;

        //for active Shell sites only - If Loyalty Program Indicator is Yes, value should be selected in either Loyalty cards or Loyalty Partner Type
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          const loyaltyInd =
            sectionEditedData?.loyaltyProgramInd?.id ||
            basicSiteDetails?.loyaltyProgramInd;

          if (loyaltyInd === "Y") {
            if (sectionEditedData?.SiteFacilitySubtype) {
              const findEle =
                sectionEditedData?.SiteFacilitySubtype?.data?.find(
                  (facility: any) =>
                    AppConst.LOYALTY_FACILITIES_CODE_LIST?.includes(
                      +facility?.facilityTypeCd
                    )
                );
              if (!findEle?.facilityTypeCd) {
                isValid = false;
                setIsFacilitySectionValid(-1);
                setFacilitySectionInvalidText(
                  Locale.create_site?.error_messages
                    ?.invalid_facility_for_loyalty
                );
              }
            } else if (fetchedSiteFacilityData) {
              const findElement =
                fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.find(
                  (facility: any) =>
                    AppConst.LOYALTY_FACILITIES_CODE_LIST?.includes(
                      +facility?.facilityTypeCd?.facilityTypeCd
                    )
                );
              if (!findElement?.facilityTypeCd) {
                isValid = false;
                setIsFacilitySectionValid(-1);
                setFacilitySectionInvalidText(
                  Locale.create_site?.error_messages
                    ?.invalid_facility_for_loyalty
                );
              }
            } else {
              isValid = false;
              setIsFacilitySectionValid(-1);
              setFacilitySectionInvalidText(
                Locale.create_site?.error_messages?.invalid_facility_for_loyalty
              );
            }
          }
        }
        //Shell Active sites, If Loyalty facility added then Loyalty indicator should set to YES
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          let isLoyaltyFacility = false;
          if (sectionEditedData?.SiteFacilitySubtype) {
            const findEle = sectionEditedData?.SiteFacilitySubtype?.data?.find(
              (facility: any) =>
                AppConst.LOYALTY_FACILITIES_CODE_LIST?.includes(
                  +facility?.facilityTypeCd
                )
            );
            if (findEle?.facilityTypeCd) {
              isLoyaltyFacility = true;
            }
          } else if (fetchedSiteFacilityData) {
            const findElement =
              fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.find(
                (facility: any) =>
                  AppConst.LOYALTY_FACILITIES_CODE_LIST?.includes(
                    +facility?.facilityTypeCd?.facilityTypeCd
                  )
              );
            if (findElement?.facilityTypeCd) {
              isLoyaltyFacility = true;
            }
          }
          if (isLoyaltyFacility) {
            if (sectionEditedData?.loyaltyProgramInd) {
              if (sectionEditedData?.loyaltyProgramInd?.id !== "Y") {
                isValid = false;
                setIsAdditionalInfoSectionValid(-1);
                setIsLoyaltyIndicatorValid(-1);
                setloyaltyIndicatorInvalidText(
                  Locale.create_site?.error_messages?.invalid_loyalty_indicator
                );
              }
            } else if (basicSiteDetails?.loyaltyProgramInd) {
              if (basicSiteDetails?.loyaltyProgramInd !== "Y") {
                isValid = false;
                setIsAdditionalInfoSectionValid(-1);
                setIsLoyaltyIndicatorValid(-1);
                setloyaltyIndicatorInvalidText(
                  Locale.create_site?.error_messages?.invalid_loyalty_indicator
                );
              }
            }
          }
        }

        //For Active Shell in network site If Selected country is USA then Mandatory to provide USA Company for Active Sites
        // const siteCountry =
        //   countrySelectedValue?.id || basicSiteDetails?.countryCd?.countryCd;
        // if (
        //   selectedStatus?.id === SiteStatusConst.ACTIVE &&
        //   siteTypeSelectedValue?.id ===
        //     SiteTypeConst.SHELL_IN_NETWORK_RETAIL_SITE &&
        //   siteCountry === "US"
        // ) {
        //   if (
        //     sectionEditedData?.usaCompanyCd ||
        //     basicSiteDetails?.usaCompanyCd
        //   ) {
        //     setIsUSACompanyValid(1);
        //   } else {
        //     isValid = false;
        //     setIsUSACompanyValid(-1);
        //     setIsAdditionalInfoSectionValid(-1);
        //   }
        // } else {
        //   setIsUSACompanyValid(1);
        // }
        

        //Mandatory for Active sites. If Facility type - 24 Hour Fuel Service is selected to No, need to provide Forecourt Days Operating Hours
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          let is24HourFuelService;


          if (sectionEditedData?.SiteFacilitySubtype) {
            is24HourFuelService =
              sectionEditedData?.SiteFacilitySubtype?.data?.find(
                (facility: any) => {
                  return (
                    +facility?.facilityTypeCd ===
                    AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
                  );
                }
              );
          } else if (fetchedSiteFacilityData) {
            is24HourFuelService =
              fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.find(
                (facility: any) => {
                  return (
                    +facility?.facilityTypeCd?.facilityTypeCd ===
                    AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
                  );
                }
              );
          }
          // if (is24HourFuelService) {
          //   try {
          //     debugger;
          //     validateOpeningHoursFor24HourService(sectionEditedData?.SiteOpeningHours);
          //   } catch (error) {
          //     isValid = false;
          //     setIsOpeningHoursSectionValid(-1);
          //     setOpeningHoursSectionInvalidText( Locale.create_site?.error_messages
          //       ?.edit_site_request_failed);
          //   }
          // }

          const openingHoursData =
            sectionEditedData?.SiteOpeningHours?.data ||
            fetchedOpeningHoursData?.data?.result?.SiteOpeningHours?.item;

          const findEle = openingHoursData?.find((facility: any) => {
            return (
              +facility?.facilityTypeCd === AppConst.FORECOURT_FACILITY_CODE
            );
          });

          if (
            !is24HourFuelService?.facilityTypeCd &&
            !findEle?.facilityTypeCd
          ) {
            isValid = false;
            setIsOpeningHoursSectionValid(-1);
            setOpeningHoursSectionInvalidText(
              Locale.create_site?.error_messages
                ?.invalid_mandatory_forecourt_hours
            );
          }
        }

        //Active sites, If All forecourt operating hours are 00:00 to 23:59 for all 7 days, Mandatory to add 24 hours facility
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          let isForecourtOperatingHoursAdded = false;
          let is24HourForecourtOperatingHours = true;
          let is24HourFuelServiceFacility;
          let is24HourFuelService1;
          is24HourFuelService1 =
          sectionEditedData?.SiteFacilitySubtype?.data?.find(
            (facility: any) => {
              return (
                +facility?.facilityTypeCd ===
                AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
              );
            }
          );
          

          const operatingHours =
            sectionEditedData?.SiteOpeningHours?.data ||
            fetchedOpeningHoursData?.data?.result?.SiteOpeningHours?.item;

          operatingHours?.forEach((facility: any) => {
            if (
              +facility?.facilityTypeCd === AppConst.FORECOURT_FACILITY_CODE
            ) {
              isForecourtOperatingHoursAdded = true;
            }
            if (
              +facility?.facilityTypeCd === AppConst.FORECOURT_FACILITY_CODE &&
              (facility?.openingFromDate?.trim() !== "00:00" ||
                facility?.openingToDate?.trim() !== "23:59")
            ) {
              is24HourForecourtOperatingHours = false;
            }
          });

          if (sectionEditedData?.SiteFacilitySubtype) {
            is24HourFuelServiceFacility =
              sectionEditedData?.SiteFacilitySubtype?.data?.find(
                (facility: any) =>
                  +facility?.facilityTypeCd ===
                  AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
              );
          } else if (fetchedSiteFacilityData) {
            is24HourFuelServiceFacility =
              fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.find(
                (facility: any) =>
                  +facility?.facilityTypeCd?.facilityTypeCd ===
                  AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
              );
          }
          if(is24HourFuelService1 && operatingHours?.length < 7)
          {
            isValid = false;
            setIsOpeningHoursSectionValid(-1);
            setOpeningHoursSectionInvalidText(
              Locale.create_site?.error_messages?._24hrweekday
            );

          }

          if (
            isForecourtOperatingHoursAdded &&
            is24HourForecourtOperatingHours &&
            operatingHours?.length === 7 &&
            !is24HourFuelServiceFacility
          ) {
            isValid = false;
            setIsOpeningHoursSectionValid(-1);
            setOpeningHoursSectionInvalidText(
              Locale.create_site?.error_messages?.invalid_24_hours_forecourt
            );
          }
        }

        //If  Active site,  24 hours facility is added then forecourt opening hours should be in 00:00 to 23:59 format
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          let is24HourFuelService;

          if (sectionEditedData?.SiteFacilitySubtype) {
            is24HourFuelService =
              sectionEditedData?.SiteFacilitySubtype?.data?.find(
                (facility: any) =>
                  +facility?.facilityTypeCd ===
                  AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
              );
          } else if (fetchedSiteFacilityData) {
            is24HourFuelService =
              fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.find(
                (facility: any) =>
                  +facility?.facilityTypeCd?.facilityTypeCd ===
                  AppConst._24_HOUR_FUEL_SERVICE_FACILITY_CODE
              );
          }

          if (is24HourFuelService) {
            if (sectionEditedData?.SiteOpeningHours) {
              const findEle = sectionEditedData?.SiteOpeningHours?.data?.find(
                (facility: any) => {
                  return (
                    +facility?.facilityTypeCd ===
                      AppConst.FORECOURT_FACILITY_CODE &&
                    (facility?.openingFromDate?.trim() !== "00:00" ||
                      facility?.openingToDate?.trim() !== "23:59")
                  );
                }
              );
              if (findEle?.facilityTypeCd) {
                isValid = false;
                setIsOpeningHoursSectionValid(-1);
                setIsFacilitySectionValid(-1);
                setOpeningHoursSectionInvalidText(
                  Locale.create_site?.error_messages?.invalid_forecourt_hours
                );
                setFacilitySectionInvalidText(Locale.create_site?.error_messages?.invalid_forecourt_hours);
              }
            } else if (fetchedOpeningHoursData) {
              const findElement =
                fetchedOpeningHoursData?.data?.result?.SiteOpeningHours?.item?.find(
                  (facility: any) => {
                    return (
                      +facility?.facilityTypeCd ===
                        AppConst.FORECOURT_FACILITY_CODE &&
                      (facility?.openingFromDate?.trim() !== "00:00" ||
                        facility?.openingToDate?.trim() !== "23:59")
                    );
                  }
                );
              if (findElement?.facilityTypeCd) {
                isValid = false;
                setIsOpeningHoursSectionValid(-1);
                setIsFacilitySectionValid(-1);
                setOpeningHoursSectionInvalidText(
                  Locale.create_site?.error_messages?.invalid_forecourt_hours
                );
                setFacilitySectionInvalidText(Locale.create_site?.error_messages?.invalid_forecourt_hours);
              }
            }
          }
        }

        //for active shell sites only - If HGV Lane is set to yes, mandatory to provide a at least one diesel product to Yes in Fuels
        //If Diesel product is added then not mandatory to have HGV lane.
        //Diesel Products - 11 Premium Diesel, 13 Fuelsave Regular Diesel, 16 Regular Diesel, 20 Hydrogen, 24 Truck Diesel, 29 DieselFit, 30 Electric Charging: Shell Recharge, 31 Electric Charging: Other, 32 Biodiesel, 35 RNG, 28 LNG
        //Refer - 1830238 - Allow HGV Lane to be added only when listed fuels are present
        //No Validation for Site type 5 in MDM
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          let isHGVLane;
          if (sectionEditedData?.SiteFacilitySubtype) {
            isHGVLane = sectionEditedData?.SiteFacilitySubtype?.data?.find(
              (facility: any) =>
                +facility?.facilityTypeCd === AppConst.CRT_FACILITY_CODE &&
                +facility?.facilitySubtypeCd ===
                  AppConst.HGV_LANE_SUB_FACILITY_CODE
            );
          } else if (fetchedSiteFacilityData) {
            isHGVLane =
              fetchedSiteFacilityData?.data?.result?.SiteFacilitySubtype?.item?.find(
                (facility: any) =>
                  +facility?.facilityTypeCd?.facilityTypeCd ===
                    AppConst.CRT_FACILITY_CODE &&
                  +facility?.facilitySubtypeCd?.facilitySubtypeCd ===
                    AppConst.HGV_LANE_SUB_FACILITY_CODE
              );
          }
          if (isHGVLane?.facilityTypeCd || isHGVLane?.facilityType) {
            if (sectionEditedData?.SiteProductClass) {
              const findEle = sectionEditedData?.SiteProductClass?.data?.find(
                (product: any) =>
                  AppConst.DIESEL_PRODUCT_CODE_LIST?.includes(
                    +product?.productClassCd
                  )
              );
              if (!findEle?.productClassCd) {
                isValid = false;
                setIsFuelProductsSectionValid(-1);
                setFuelProductsSectionInvalidText(
                  Locale.create_site?.error_messages
                    ?.invalid_product_for_hgv_lane
                );
              }
            } else if (fetchedFuelProductsData) {
              const findElement =
                fetchedFuelProductsData?.data?.result?.SiteProductClass?.item?.find(
                  (product: any) =>
                    AppConst.DIESEL_PRODUCT_CODE_LIST?.includes(
                      +product?.productClassCd?.productClassCd
                    )
                );
              if (!findElement?.productClassCd) {
                isValid = false;
                setIsFuelProductsSectionValid(-1);
                setFuelProductsSectionInvalidText(
                  Locale.create_site?.error_messages
                    ?.invalid_product_for_hgv_lane
                );
              }
            } else {
              setIsFuelProductsSectionValid(-1);
              setFuelProductsSectionInvalidText(
                Locale.create_site?.error_messages?.invalid_product_for_hgv_lane
              );
            }
          }
        }

        //Active sites, Fuel is required
        if (
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE)
        ) {
          const products =
            sectionEditedData?.SiteProductClass?.data ||
            fetchedFuelProductsData?.data?.result?.SiteProductClass?.item;

          if (!products?.length) {
            isValid = false;
            setIsFuelProductsSectionValid(-1);
            setFuelProductsSectionInvalidText(
              Locale.create_site?.error_messages?.fuel_product_validation
            );
          }
        }

        //Check if retailer section is valid
        if (
          classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
          classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
        ) {
          if (
            isCreate &&
            isRetailerSectionValid !== 1 &&
            selectedStatus?.id === SiteStatusConst.ACTIVE &&
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
          ) {
            setIsRetailerSectionValid(-1);
            isValid = false;
          } else if (
            !isCreate &&
            selectedStatus?.id === SiteStatusConst.ACTIVE &&
            isRetailerSectionValid !== 1 &&
            sectionEditedData?.SiteRetailer &&
            classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
          ) {
            setIsRetailerSectionValid(-1);
            isValid = false;
          }
        }

        //check space utilization section is valid or not
        if (
          classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
          classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE
        ) {
          const siteSizeArea =
            sectionEditedData?.siteSizeArea || basicSiteDetails?.siteSizeArea;

          const shopBuildingSize =
            sectionEditedData?.shopBuildingSize ||
            basicSiteDetails?.shopBuildingSize;
          if (
            selectedStatus?.id === SiteStatusConst.ACTIVE &&
            operatingPlatform &&
            AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
              operatingPlatform?.value
            ) &&
            (!siteSizeArea ||
              siteSizeArea === undefined ||
              siteSizeArea === null ||
              siteSizeArea <= 1)
          ) {
            setIsSpaceUtilizationSectionValid(-1);
            setIsSiteSizeAreaValid(-1);
            isValid = false;
          }

          if (
            selectedStatus?.id === SiteStatusConst.ACTIVE &&
            operatingPlatform &&
            AppConst.COMPANY_OWNED_RE_TYPE_LIST?.includes(
              operatingPlatform?.value
            ) &&
            (!shopBuildingSize ||
              shopBuildingSize === undefined ||
              shopBuildingSize === null)
          ) {
            setIsSpaceUtilizationSectionValid(-1);
            setIsShopBuildingSizeValid(-1);
            isValid = false;
          }
        }

        // Loyalty program indicator in mandatory field for Active sites
        if (
          isCreate &&
          selectedStatus?.id === SiteStatusConst.ACTIVE &&
          !sectionEditedData?.loyaltyProgramInd?.id &&
          (classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
            classifiedSiteType === AppConst.RBLA_SITE_TYPE)
        ) {
          setIsAdditionalInfoSectionValid(-1);
          setIsLoyaltyIndicatorValid(-1);
          setloyaltyIndicatorInvalidText(Locale.common?.required_field);
          isValid = false;
        }

        //Check Fax no is in Valid format
        if (
          sectionEditedData?.faxNo &&
          !AppConst.NUMBER_ONLY_FIELD_REGULAR_EXP?.test(
            sectionEditedData?.faxNo
          )
        ) {
          isValid = false;
          setIsFaxNoValid(-1);
        }
        return isValid;
      },
    }));

    const onFacilityDataUpdate = () => {
      setIsFacilitySectionValid(0);
      setIsOpeningHoursSectionValid(0);
      setIsAdditionalInfoSectionValid(0);
    };

    const onFuelProductsDataUpdate = () => {
      setIsFuelProductsSectionValid(0);
    };

    const onOpeningHoursDataUpdate = () => {
      setIsOpeningHoursSectionValid(0);
    };

    const onLoyaltyIndicatorUpdate = () => {
      setIsAdditionalInfoSectionValid(0);
      setIsLoyaltyIndicatorValid(0);
    };

    const onUSACompanyUpdate = () => {
      setIsAdditionalInfoSectionValid(0);
      setIsUSACompanyValid(0);
    };
    const onEvOverviewValueChanged = (flag: boolean) => {
      setIsEvOverviewValueChanged(flag);
    };

    const onFaxNoUpdate = () => {
      setIsAdditionalInfoSectionValid(0);
      setIsFaxNoValid(0);
    };

    const onSiteSizeAreaUpdate = () => {
      setIsSpaceUtilizationSectionValid(0);
      setIsSiteSizeAreaValid(0);
    };

    const onShopBuildingSizeUpdate = () => {
      setIsSpaceUtilizationSectionValid(0);
      setIsShopBuildingSizeValid(0);
    };

    return (
      <div className="mdm--site-detail mdm--sd-basic-details-top-section">
        <TabStrip
          selected={basicDetailsTabSelected}
          onSelect={handleBasicDetailsTabSelect}
          className={"mdm--sd-details-child-tab"}
        >
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.retail_admin_update.facility
                      .facility_label
                  }
                </span>
                {isFacilitySectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE
            }
          >
            <FacilityTab
              siteId={siteId}
              isEditing={isEditing}
              basicDetailsTabSelected={basicDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              onFacilityDataUpdate={onFacilityDataUpdate}
              classifiedSiteType={classifiedSiteType}
              selectedStatus={selectedStatus}
              isCreate={isCreate}
              isSectionValid={isFacilitySectionValid}
              sectionInvalidText={facilitySectionInvalidText}
              refetchFacilityData={refetchFacilityData}
              fetchedSiteFacilityData={fetchedSiteFacilityData}
              isSiteFacilityDataLoading={isSiteFacilityDataLoading}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.retail_admin_update.fuel_products
                      .fuel_products_label
                  }
                </span>
                {isFuelProductsSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE ||
              classifiedSiteType === AppConst.PARTNER_SITE_TYPE
            }
          >
            <FuelProductsTab
              siteId={siteId}
              isEditing={isEditing}
              basicDetailsTabSelected={basicDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              onFuelProductsDataUpdate={onFuelProductsDataUpdate}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              selectedCategory={selectedCategory}
              fetchedFuelProductsData={fetchedFuelProductsData}
              isFuelProductsDataLoading={isFuelProductsDataLoading}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              onChangeCategory={onChangeCategory}
              isSectionValid={isFuelProductsSectionValid}
              sectionInvalidText={fuelProductsSectionInvalidText}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.retail_admin_update.opening_hours
                      .opening_hours_label
                  }
                </span>
                {isOpeningHoursSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE ||
              classifiedSiteType === AppConst.PARTNER_SITE_TYPE
            }
          >
            <OpeningHoursTab
              siteId={siteId}
              isEditing={isEditing}
              onOpeningHoursDataUpdate={onOpeningHoursDataUpdate}
              basicDetailsTabSelected={basicDetailsTabSelected}
              facilityTypeCodes={facilityTypeCodes}
              isFacilityTypeCodesLoading={isFacilityTypeCodesLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              isSectionValid={isOpeningHoursSectionValid}
              sectionInvalidText={openingHoursSectionInvalidText}
              fetchedOpeningHoursData={fetchedOpeningHoursData}
              isOpeningHoursDataLoading={isOpeningHoursDataLoading}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.retail_admin_update.retailer
                      .retailer_label
                  }
                </span>
                {isRetailerSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              classifiedSiteType === AppConst.RBLA_SITE_TYPE ||
              classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE ||
              classifiedSiteType === AppConst.PARTNER_SITE_TYPE
            }
          >
            {classifiedSiteType === AppConst.GSAP_SITE_TYPE && (
              <RetailerTabGSAP
                siteId={siteId}
                isCreate={isCreate}
                isEditing={isEditing}
                basicDetailsTabSelected={basicDetailsTabSelected}
              />
            )}
            {classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE && (
              <RetailerTab
                siteId={siteId}
                isEditing={isEditing}
                basicDetailsTabSelected={basicDetailsTabSelected}
                countryCodes={countryCodes}
                refetchTerritoryCodes={refetchTerritoryCodes}
                territoryCodes={territoryCodes}
                isTerritoryCodesLoading={isTerritoryCodesLoading}
                isCountryCodesLoading={isCountryCodesLoading}
                onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
                onSectionValueSet={onBasicDetailsEditedDataValueSet}
                isSectionValueChanged={isTabValueChanged}
                onSectionValueChanged={onTabValueChanged}
                sectionEditedData={sectionEditedData}
                isCreate={isCreate}
                classifiedSiteType={classifiedSiteType}
                setIsSectionValid={onRetailersDataChange}
                isSectionValid={isRetailerSectionValid}
                refetchAllData={refetchAllData}
              />
            )}
          </TabStripTab>
          <TabStripTab
            title={
              Locale.site_details?.retail_admin_update.legacy_ids
                .legacy_ids_label
            }
            disabled={
              !classifiedSiteType ||
              (
              classifiedSiteType !== AppConst.RBLA_SITE_TYPE  &&
              classifiedSiteType !== AppConst.PARTNER_SITE_TYPE &&
              classifiedSiteType !== AppConst.COMPETITOR_SITE_TYPE &&
              classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
              classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE &&
              classifiedSiteType !== AppConst.COMPANY_OWNED_RE_TYPE   
              )
            }
          >
            <LegacyIdsTab
              siteId={siteId}
              isEditing={isEditing}
              basicDetailsTabSelected={basicDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              classifiedSiteType={classifiedSiteType}
              isCreate={isCreate}
              refetchAllData={refetchAllData}
            />
          </TabStripTab>
          <TabStripTab
            title={
              Locale.site_details?.ev_details.External_ids.external_ids_label
            }
            disabled={!classifiedSiteType}
          >
            <ExternalIdsTab
              siteId={siteId}
              isEditing={isEditing}
              basicDetailsTabSelected={basicDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              fetchedExternalIdData={fetchedExternalIdData}
              isExternalIdDataLoading={isExternalIdDataLoading}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.ev_details.space_utilisation
                      .space_utilisation_label
                  }
                </span>
                {isSpaceUtilizationSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={
              !classifiedSiteType ||
              (classifiedSiteType !== AppConst.GSAP_SITE_TYPE &&
                classifiedSiteType !== AppConst.NON_GSAP_SITE_TYPE)
            }
          >
            <SpaceUtilisationTab
              siteId={siteId}
              isEditing={isEditing}
              basicDetailsTabSelected={basicDetailsTabSelected}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              isCreate={isCreate}
              classifiedSiteType={classifiedSiteType}
              isSectionValid={isSpaceUtilizationSectionValid}
              isSiteSizeAreaValid={isSiteSizeAreaValid}
              isShopBuildingSizeValid={isShopBuildingSizeValid}
              onSiteSizeAreaUpdate={onSiteSizeAreaUpdate}
              onShopBuildingSizeUpdate={onShopBuildingSizeUpdate}
              selectedStatus={selectedStatus}
              operatingPlatform={operatingPlatform}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.retail_admin_update.network
                      .network_label
                  }
                </span>
              </>
            }
            disabled={
              !classifiedSiteType ||
              classifiedSiteType === AppConst.RBLA_SITE_TYPE ||
              classifiedSiteType === AppConst.PARTNER_SITE_TYPE ||
              classifiedSiteType === AppConst.COMPETITOR_SITE_TYPE
            }
          >
            <NetworkTab
              siteId={siteId}
              isEditing={isEditing}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              basicDetailsTabSelected={basicDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              classifiedSiteType={classifiedSiteType}
              isCreate={isCreate}
              // setIsSectionValid={onNetworkDataChange}
              isSectionValid={isNetworkSectionValid}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <>
                <span>
                  {
                    Locale.site_details?.retail_admin_update
                      .additional_site_info.additional_site_info_label
                  }
                </span>
                {isAdditionalInfoSectionValid === -1 &&
                  isEditing &&
                  (classifiedSiteType === AppConst.GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.NON_GSAP_SITE_TYPE ||
                    classifiedSiteType === AppConst.RBLA_SITE_TYPE) && (
                    <Tooltip anchorElement="target" position="top">
                      <div className="tab-error-icon">
                        <span
                          title={
                            Locale.create_site?.error_messages?.invalid_data
                          }
                          className="k-icon k-i-warning"
                        />
                      </div>
                    </Tooltip>
                  )}
              </>
            }
            disabled={!classifiedSiteType}
          >
            <AdditionalInfoTab
              siteId={siteId}
              isEditing={isEditing}
              basicSiteDetails={basicSiteDetails}
              isBasicSiteDetailsLoading={isBasicSiteDetailsLoading}
              basicDetailsTabSelected={basicDetailsTabSelected}
              onPatchRequestValueSet={onBasicDetailsPatchDataValueSet}
              onSectionValueSet={onBasicDetailsEditedDataValueSet}
              isSectionValueChanged={isTabValueChanged}
              onSectionValueChanged={onTabValueChanged}
              sectionEditedData={sectionEditedData}
              classifiedSiteType={classifiedSiteType}
              isCreate={isCreate}
              isLoyaltyIndicatorValid={isLoyaltyIndicatorValid}
              loyaltyIndicatorInvalidText={loyaltyIndicatorInvalidText}
              onLoyaltyIndicatorUpdate={onLoyaltyIndicatorUpdate}
              onUSACompanyUpdate={onUSACompanyUpdate}
              isUSACompanyValid={isUSACompanyValid}
              isFaxNoValid={isFaxNoValid}
              onFaxNoUpdate={onFaxNoUpdate}
             isEvOverviewValueChanged={isEvOverviewValueChanged}
             onEvOverviewValueChanged={onEvOverviewValueChanged}

            />
              
          </TabStripTab>
        </TabStrip>
        {isCreate && (
          <div className="notification-message">{`${
            !classifiedSiteType ? Locale.create_site?.select_site_type : ""
          }`}</div>
        )}
      </div>
    );
  })
);
